import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useAuth0 } from '../../react-auth0-spa';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <Button onClick={() => loginWithRedirect({})}>
        Log in
      </Button>
      <Typography variant="h3">
        Boilerplate Auth0 React Flask
      </Typography>
    </>
  );
};

export default LoginPage;
