import { createMuiTheme } from '@material-ui/core/styles';
import { grey, blue } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    info: {
      main: grey[200],
      dark: grey[400],
    },
  },
});
