import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useAuth0 } from '../../react-auth0-spa';
import HomePage from '../../views/home';
import LoginPage from '../../views/login';

const ProtectedRoute = ({ children }:any) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: '/login' }}
      />
    );
  }

  return children;
};

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    </Route>
    <Route>
      <LoginPage />
    </Route>
  </Switch>
);

export default Routes;
