import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import theme from './theme';
import { useAuth0 } from '../react-auth0-spa';
import Routes from './routes';

const AppSetup = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Router>
  );
};

export default AppSetup;
