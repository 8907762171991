import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { useAuth0 } from '../../react-auth0-spa';
import { API_URL } from '../../config';

const HomePage = () => {
  const { logout } = useAuth0();
  const [apiResponse, setApiResponse] = useState<string | null >(null);
  const [error, setError] = useState<boolean>(false);

  const tryApi = async () => {
    try {
      const r = await axios({
        method: 'get',
        url: `${API_URL}/some-route`,
        headers: {
          authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      setApiResponse(r.data);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    tryApi();
  }, []);

  return (
    <>
      <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
      <Typography variant="h3">Logged in</Typography>
      {error
      && (
        <Typography>
          API integration error
        </Typography>
      )}
      {!error && !apiResponse
      && (
        <Typography>
          Executing API request...
        </Typography>
      )}
      {apiResponse
      && (
        <Typography>
          {`API response to route 'some-route' was "${JSON.stringify(apiResponse)}"`}
        </Typography>
      )}
    </>
  );
};

export default HomePage;
