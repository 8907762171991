export const getJwtApiToken = () => {
  return localStorage.get('jwtToken');
};

export const setJwtApiToken = (token:string | null) => {
  if (token === null) {
    localStorage.removeItem('jwtToken');
    return;
  }
  localStorage.setItem('jwtToken', token);
};

export const clearStore = () => localStorage.clear();
